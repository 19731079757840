// Estilos globales y de librerías
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";

// Dependencias de React y Next.js
import React, { useEffect, useRef, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
// Proveedores y componentes de terceros
import { UserProvider, useUser } from "@auth0/nextjs-auth0/client";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { ToastContainer } from "react-toastify";

// Componentes y contextos propios
import LoadingSpinner from "../Components/LoadingSpinner";
import { AppWrapper } from "../context/state";

// Integraciones de monitoreo y análisis
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

// Constantes de configuración
const AMPLITUDE_API_KEY = "dee622db9fc22974a61a156d496d9851";

// Inicialización de Bugsnag (solo una vez)
if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: "1a657fa52ee13ce9f07960a1ed527d51",
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: "1a657fa52ee13ce9f07960a1ed527d51" });
}
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

/* ====================
   HOOKS PERSONALIZADOS
   ==================== */

// Hook para controlar el estado de carga en la navegación
function useRouteLoading() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setIsLoading(true);
    const handleComplete = () => setIsLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router.events]);

  return isLoading;
}


// Hook para inicializar Amplitude y su plugin de Session Replay
// function useAmplitudeInitialization() {
//   useEffect(() => {
//     async function initializeAmplitude() {
//       if (!amplitude.initialized) {
//         const sessionReplay = sessionReplayPlugin({
//           serverZone: "EU",
//         });
//         amplitude.add(sessionReplay);

//         amplitude.init(AMPLITUDE_API_KEY, {
//           autocapture: true,
//           serverZone: "EU",
//         });
//         amplitude.initialized = true; // Evita reinicializaciones

//         console.log("Session Replay & Amplitude initialized");
//       }
//     }
//     initializeAmplitude();
//   }, []);
// }

function useAmplitudeInitialization() {
  const initialized = useRef(false); // Usa useRef para evitar reinicializaciones

  useEffect(() => {
    if (!initialized.current) {
      const sessionReplay = sessionReplayPlugin({
        serverZone: "EU",
      });
      amplitude.add(sessionReplay);

      amplitude.init(AMPLITUDE_API_KEY, {
        autocapture: true,
        serverZone: "EU",
      });

      initialized.current = true;
      console.log("Session Replay & Amplitude initialized");
    }
  }, []);
}

// Hook para registrar el Service Worker
function useServiceWorkerRegistration() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js", { scope: "/" })
          .then((registration) => {
            console.log(
              "Service Worker registrado con scope:",
              registration.scope
            );
          })
          .catch((error) => {
            console.error("Error al registrar el Service Worker:", error);
          });
      });
    }
  }, []);
}

/* ============================
   COMPONENTE DE INICIALIZACIÓN
   ============================ */

function UserInitializer() {
  const { user, isLoading } = useUser();
  const identified = useRef(false);

  useEffect(() => {
    if (!isLoading && user && !identified.current) {
      // Configuración e identificación en Amplitude
      amplitude.setUserId(user.sub);
      const identifyEvent = new amplitude.Identify()
        .set("email", user.email)
        .set("name", user.name)
        .set("role", user.user_roles?.[0] || "none")
        .set("company", user.user_metadata?.companyName || "none");

      amplitude.identify(identifyEvent);
      identified.current = true; // Evita re-identificación

      // Inicialización de Pendo (asegúrate de que `window.pendo` esté disponible)
      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: user.sub, // ID único del visitante
            // Se pueden añadir más propiedades aquí
          },
        });
      }

      console.log("Usuario identificado correctamente.");
    }
  }, [user, isLoading]);

  return null; // Este componente no renderiza nada
}

/* ============================
   COMPONENTE PRINCIPAL (APP)
   ============================ */

const Plaved = ({ Component, pageProps }) => {
  const isLoading = useRouteLoading();

  // Inicialización de Amplitude y registro del Service Worker
  useAmplitudeInitialization();
  useServiceWorkerRegistration();

  return (
    <ErrorBoundary>
      <UserProvider>
        <UserInitializer />

          <AppWrapper>
            <Head>
              <title>PLAVED</title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
            </Head>

            {isLoading && <LoadingSpinner />}

            <ToastContainer
              position="top-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

            <Component {...pageProps} />

            <SpeedInsights />
          </AppWrapper>
      </UserProvider>
    </ErrorBoundary>
  );
};

export default Plaved;